import React, { PureComponent } from 'react';
import { Mutation } from '@apollo/react-components';
import { Button, Form, Input, InputNumber, message, Select } from 'antd/es';
import { REGISTER_FIRM } from '../../graphql/firms/register-firm';
import { GET_FIRM_LIST } from '../../graphql/firms/get-firm-list';
import './Forms.scss';

class FirmRegistrationForm extends PureComponent {
  saveValuesToState = (val) => {
    this.setState(val);
  };

  render() {
    // Set up form
    const layout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    };

    const { Option } = Select;

    return (
      <Mutation
        mutation={REGISTER_FIRM}
        update={(cache, mutationResult) => {
          const query = cache.readQuery({
            query: GET_FIRM_LIST,
          });

          const firmData = {
            firms: [mutationResult.data.registerFirm, ...query.firms],
          };

          cache.writeQuery({
            query: GET_FIRM_LIST,
            data: firmData,
          });
        }}
        onError={(data) => {
          message.error(`Sorry, an error occurred`, 4);
        }}
        onCompleted={(data) => {
          const { name } = data.registerFirm;

          message.success(
            <span className="u-standardcase">{name} has been registered successfully</span>,
            4
          );

          /* Close modal */
          this.props.registerFirmModalVisible(false);
        }}
      >
        {(registerFirm, { loading, error, data }) => {
          return (
            <Form
              requiredMark={false}
              {...layout}
              name="firmRegistrationForm"
              className="firm-registration-form"
              layout="vertical"
              onFinish={(values) => {
                registerFirm({
                  variables: {
                    input: {
                      name: values.firmName,
                      type: values.firmType,
                      companyNumber: values.companyNumber,
                      sraNumber: values.sraNumber,
                      seatCount: values.seatCount,
                    },
                  },
                });
              }}
              onValuesChange={this.saveValuesToState}
            >
              <Form.Item
                label="Firm name"
                name="firmName"
                validateTrigger={['onBlur']}
                rules={[{ required: true, message: 'Firm name is required' }]}
              >
                <Input spellCheck="false" />
              </Form.Item>
              <Form.Item
                label="Firm type"
                name="firmType"
                validateTrigger={['onBlur']}
                rules={[{ required: true, message: 'Firm type is required' }]}
              >
                <Select placeholder="select firm type">
                  {this.props.firmTypes.map((type, index) => {
                    return (
                      <Option key={type.name + index} value={type.name}>
                        {type.name.replace(/_/g, ' ').toLowerCase()}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Company/LLP number"
                name="companyNumber"
                validateTrigger={['onBlur']}
                rules={[
                  { required: true, message: 'Company/LLP number is required' },
                  { min: 8, max: 8, message: 'Company/LLP number must be 8 characters/digits' },
                ]}
              >
                <Input spellCheck="false" />
              </Form.Item>
              <Form.Item
                label="SRA ID number"
                name="sraNumber"
                validateTrigger={['onBlur']}
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject('SRA ID number is required');
                      }

                      if (!value.match(/^[0-9]+$/)) {
                        return Promise.reject('SRA ID number must only include numbers');
                      }

                      if (value.length < 5 || value.length > 6) {
                        return Promise.reject('SRA ID number must be 5 or 6 digits');
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input spellCheck="false" />
              </Form.Item>
              <Form.Item
                label="Seat count"
                name="seatCount"
                validateTrigger={['onBlur', 'onChange']}
                rules={[
                  {
                    required: true,
                    message: 'Seat count must be a minimum of 1',
                    type: 'number',
                    min: 1,
                  },
                ]}
              >
                <InputNumber min={1} />
              </Form.Item>
              <Form.Item
                name="submitButton"
                className="u-text-center"
                wrapperCol={{ ...layout.wrapperCol, offset: 0 }}
              >
                <Button type="primary" htmlType="submit" loading={loading}>
                  {loading ? 'Registering firm' : 'Register firm'}
                </Button>
              </Form.Item>
            </Form>
          );
        }}
      </Mutation>
    );
  }
}

export default FirmRegistrationForm;
