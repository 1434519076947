import React, { PureComponent } from 'react';
import { Mutation } from '@apollo/react-components';
import { MoreOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Popover, message, Modal } from 'antd/es';
import TaskQuestionForm from '../Forms/TaskQuestionForm';
import ReorderTaskQuestionForm from '../Forms/ReorderTaskQuestionForm';
import { ReactComponent as IconModalClose } from '../../assets/images/icon-modal-close.svg';
import { REMOVE_QUESTION } from '../../graphql/tasks/questions/remove-question';
import { GET_TASK } from '../../graphql/tasks/get-task';
import './TaskQuestion.scss';

class TaskQuestion extends PureComponent {
  constructor(props) {
    super();
    this.state = {
      editTaskQuestionModalVisible: false,
      editTaskQuestionTextModalVisible: false,
      reorderTaskQuestionModalVisible: false,
      showingMore: false,
    };
  }

  editTaskQuestionModalVisible = () => {
    this.setState((prevState) => {
      return { editTaskQuestionModalVisible: !prevState.editTaskQuestionModalVisible };
    });
  };

  editTaskQuestionTextModalVisible = () => {
    this.setState((prevState) => {
      return { editTaskQuestionTextModalVisible: !prevState.editTaskQuestionTextModalVisible };
    });
  };

  reorderTaskQuestionModalVisible = () => {
    this.setState((prevState) => {
      return { reorderTaskQuestionModalVisible: !prevState.reorderTaskQuestionModalVisible };
    });
  };

  componentDidMount() {
    const question = this.refs.question;
    const questionText = question.querySelector('.question__text');

    const showHideSeeMoreBtn = () => {
      const seeMoreBtn = question.querySelector('.question__see-more-btn');

      if (questionText.clientWidth < questionText.scrollWidth) {
        seeMoreBtn.style.display = 'block';
      }

      if (questionText.clientWidth >= questionText.scrollWidth) {
        seeMoreBtn.style.display = 'none';
      }

      if (questionText.clientWidth >= questionText.scrollWidth && this.state.showingMore === true) {
        seeMoreBtn.style.display = 'block';
      }

      if (questionText.clientHeight < 34 && this.state.showingMore === true) {
        seeMoreBtn.style.display = 'none';
        questionText.classList.toggle('question__text--truncated');

        this.setState((prevState) => {
          return {
            showingMore: !prevState.showingMore,
          };
        });
      }
    };

    showHideSeeMoreBtn();

    window.addEventListener('resize', showHideSeeMoreBtn);
  }

  render() {
    return (
      <>
        <div className="question" ref="question">
          <div className="question__text question__text--truncated">
            {this.props.questionData.text}
            {this.props.questionData.description && (
              <span className="question__desc"> - {this.props.questionData.description}</span>
            )}
          </div>
          <Button
            type="link"
            className="question__see-more-btn"
            onClick={(e) => {
              const questionText = this.refs.question.querySelector('.question__text');

              questionText.classList.toggle('question__text--truncated');
              this.setState((prevState) => {
                return {
                  showingMore: !prevState.showingMore,
                };
              });
            }}
          >
            {this.state.showingMore ? 'See less' : 'See more'}
          </Button>
          <Popover
            content={
              <>
                {/* Edit question */}
                <Button
                  type="link"
                  className="p-0"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.editTaskQuestionModalVisible();
                  }}
                >
                  Edit question
                </Button>

                {/* Reorder question */}
                {this.props.sectionData.questionCount > 1 && (
                  <Button
                    type="link"
                    className="p-0"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.reorderTaskQuestionModalVisible();
                    }}
                  >
                    Reorder question
                  </Button>
                )}

                {/* Remove question */}
                <Mutation
                  mutation={REMOVE_QUESTION}
                  variables={{
                    input: {
                      questionId: this.props.questionData.id,
                    },
                  }}
                  refetchQueries={[
                    { query: GET_TASK, variables: { taskId: this.props.taskData.id } },
                  ]}
                  onError={(data) => {
                    message.error(`Sorry, an error occurred`, 4);
                  }}
                  onCompleted={(data) => {
                    message.success(`the question has been deleted successfully`, 4);
                  }}
                >
                  {(removeQuestion, { loading, error, data }) => {
                    const confirmRemoveTask = () => {
                      Modal.confirm({
                        title: 'remove question',
                        icon: <ExclamationCircleOutlined />,
                        content: `Are you sure you want to remove the '${this.props.questionData.text}' question?`,
                        centered: true,
                        okText: 'Remove',
                        cancelText: 'Cancel',
                        onOk: removeQuestion,
                      });
                    };
                    return (
                      <Button
                        type="link"
                        className="p-0"
                        onClick={(e) => {
                          e.stopPropagation();
                          confirmRemoveTask();
                        }}
                      >
                        Remove question
                      </Button>
                    );
                  }}
                </Mutation>
              </>
            }
            trigger="click"
            placement="left"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <MoreOutlined style={{ fontSize: 18 }} />
          </Popover>
        </div>

        {/* Edit question */}
        <Modal
          title="Edit question"
          className="task-question-modal"
          centered
          destroyOnClose={true}
          open={this.state.editTaskQuestionModalVisible}
          onCancel={this.editTaskQuestionModalVisible}
          footer={null}
          width="1100px"
          maskClosable={false}
          closeIcon={<IconModalClose />}
        >
          <TaskQuestionForm
            closeQuestionModal={this.editTaskQuestionModalVisible}
            taskId={this.props.taskData.id}
            sectionId={this.props.sectionData.id}
            questionId={this.props.questionData.id}
          />
        </Modal>

        {/* Reorder question modal */}
        <Modal
          title="Reorder question"
          centered
          destroyOnClose={true}
          open={this.state.reorderTaskQuestionModalVisible}
          onCancel={this.reorderTaskQuestionModalVisible}
          footer={null}
          width="500px"
          maskClosable={false}
          closeIcon={<IconModalClose />}
        >
          <ReorderTaskQuestionForm
            reorderTaskQuestionModalVisible={this.reorderTaskQuestionModalVisible}
            taskId={this.props.taskData.id}
            sectionId={this.props.sectionData.id}
            questionId={this.props.questionData.id}
            questionCount={this.props.sectionData.questionCount}
          />
        </Modal>
      </>
    );
  }
}

export default TaskQuestion;
