import React, { PureComponent } from 'react';
import { Query, Mutation } from '@apollo/react-components';
import { Link, Redirect } from 'react-router-dom';
import Loading from '../Loading';
import {
  ArrowLeftOutlined,
  MoreOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Button, Popover, Modal, Tag, message, Tooltip } from 'antd/es';
import TaskSectionPanel from './TaskSectionPanel';
import AddTaskSectionForm from '../Forms/AddTaskSectionForm';
import RenameTaskForm from '../Forms/RenameTaskForm';
import UpdateTaskDescForm from '../Forms/UpdateTaskDescForm';
import UpdateTaskOfficialAdviceForm from '../Forms/UpdateTaskOfficialAdviceForm';
import ChangeTaskMatterTypesForm from '../Forms/ChangeTaskMatterTypesForm';
import { ReactComponent as IconModalClose } from '../../assets/images/icon-modal-close.svg';
import { ReactComponent as IconMatters } from '../../assets/images/icon-matters.svg';
import { ReactComponent as IconTaskPublishedStatus } from '../../assets/images/icon-task-published-status.svg';
import { ReactComponent as IconDescription } from '../../assets/images/icon-description.svg';
import { ReactComponent as IconAdvice } from '../../assets/images/icon-advice.svg';
import { GET_TASK } from '../../graphql/tasks/get-task';
import { REMOVE_TASK } from '../../graphql/tasks/remove-task';
import { LIST_TASKS } from '../../graphql/tasks/list-tasks';
import { CHANGE_TASK_PUBLISH_STATUS } from '../../graphql/tasks/change-task-publish-status';
import './TaskDetails.scss';

class TaskDetails extends PureComponent {
  constructor(props) {
    super();
    this.state = {
      addTaskSectionModalVisible: false,
      renameTaskModalVisible: false,
      updateTaskDescModalVisible: false,
      redirectAfterTaskDel: false,
      changeMatterTypesModalVisible: false,
      popoverVisible: false,
      taskname: '',
      taskPublishedStatus: '',
    };
  }

  handlePopoverVisibleChange = (popoverVisible) => {
    this.setState({ popoverVisible });
  };

  addTaskSectionModalVisible = () => {
    this.setState((prevState) => {
      return { addTaskSectionModalVisible: !prevState.addTaskSectionModalVisible };
    });
  };

  renameTaskModalVisible = () => {
    this.setState((prevState) => {
      return { renameTaskModalVisible: !prevState.renameTaskModalVisible };
    });
  };

  updateTaskDescModalVisible = () => {
    this.setState((prevState) => {
      return { updateTaskDescModalVisible: !prevState.updateTaskDescModalVisible };
    });
  };

  updateTaskOfficialAdviceModalVisible = () => {
    this.setState((prevState) => {
      return {
        updateTaskOfficialAdviceModalVisible: !prevState.updateTaskOfficialAdviceModalVisible,
      };
    });
  };

  changeMatterTypesModalVisible = () => {
    this.setState((prevState) => {
      return { changeMatterTypesModalVisible: !prevState.changeMatterTypesModalVisible };
    });
  };

  createSections = (taskData) => {
    const { sections } = taskData;
    const sectionsOutput = sections.map((sectionData) => {
      return (
        <TaskSectionPanel key={sectionData.id} taskData={taskData} sectionData={sectionData} />
      );
    });
    return sectionsOutput;
  };

  render() {
    if (this.state.redirectAfterTaskDel) {
      return <Redirect to="/tasks" />;
    } else {
      return (
        <Query
          query={GET_TASK}
          variables={{
            taskId: this.props.match.params.id,
          }}
          onCompleted={(data) => {
            this.setState({ taskName: data.task.name });
            this.setState({ taskPublishedStatus: data.task.isPublished });
          }}
        >
          {({ loading, error, data }) => {
            if (loading) {
              return <Loading />;
            }

            if (error) return <p>Error :(</p>;

            return (
              <>
                <Button ghost type="primary" className="back-btn">
                  <Link to="/tasks">
                    <ArrowLeftOutlined />
                  </Link>
                </Button>

                <div className="task-details-header">
                  <h1 className="task-details-header__heading">{data.task.name}</h1>
                  <Button
                    type="primary"
                    className="u-flex-align-center task-details-header__add-btn"
                    onClick={this.addTaskSectionModalVisible}
                  >
                    <PlusOutlined />
                    Add section
                  </Button>
                  <Popover
                    trigger="click"
                    placement="left"
                    visible={this.state.popoverVisible}
                    onVisibleChange={this.handlePopoverVisibleChange}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    content={
                      <>
                        <Button
                          type="link"
                          className="p-0"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.setState({ popoverVisible: false });
                            this.renameTaskModalVisible();
                          }}
                        >
                          Rename task
                        </Button>

                        <Button
                          type="link"
                          className="p-0"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.setState({ popoverVisible: false });
                            this.updateTaskDescModalVisible();
                          }}
                        >
                          Update description
                        </Button>

                        <Button
                          type="link"
                          className="p-0"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.setState({ popoverVisible: false });
                            this.updateTaskOfficialAdviceModalVisible();
                          }}
                        >
                          Update task advice
                        </Button>

                        <Button
                          type="link"
                          className="p-0"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.setState({ popoverVisible: false });
                            this.changeMatterTypesModalVisible();
                          }}
                        >
                          Change matter types
                        </Button>

                        {/* Change library task publish status */}
                        <Mutation
                          mutation={CHANGE_TASK_PUBLISH_STATUS}
                          variables={{
                            input: {
                              taskId: this.props.match.params.id,
                              isPublished: this.state.taskPublishedStatus ? false : true,
                            },
                          }}
                          refetchQueries={[
                            { query: GET_TASK, variables: { taskId: this.props.match.params.id } },
                          ]}
                          onCompleted={(data) => {
                            const { name, isPublished } = data.changeTaskPublishStatus;
                            message.success(
                              <span className="u-standardcase">
                                {`'${name}' has been ${isPublished ? 'published' : 'unpublished'}`}
                              </span>,
                              4
                            );
                            this.setState((prevState) => {
                              return { taskPublishedStatus: !prevState.taskPublishedStatus };
                            });
                          }}
                          onError={(data) => {
                            message.error(`Sorry, an error occurred`, 4);
                          }}
                        >
                          {(changeTaskPublishStatus, { loading, error, data }) => {
                            const confirmPublishStatus = () => {
                              Modal.confirm({
                                title: (
                                  <span className="u-standardcase">{this.state.taskName}</span>
                                ),
                                icon: <ExclamationCircleOutlined />,
                                content: `Are you sure you want to ${
                                  this.state.taskPublishedStatus ? 'unpublish' : 'publish'
                                } this task?`,
                                centered: true,
                                okText: `${
                                  this.state.taskPublishedStatus ? 'unpublish' : 'publish'
                                }`,
                                cancelText: 'Cancel',
                                onOk: changeTaskPublishStatus,
                              });
                            };

                            return (
                              <Button
                                type="link"
                                className="p-0"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  confirmPublishStatus();
                                  this.setState({ popoverVisible: false });
                                }}
                              >
                                {this.state.taskPublishedStatus ? 'unpublish task' : 'publish task'}
                              </Button>
                            );
                          }}
                        </Mutation>

                        {/* Remove task */}
                        <Mutation
                          mutation={REMOVE_TASK}
                          variables={{
                            input: {
                              taskId: this.props.match.params.id,
                            },
                          }}
                          update={(cache, mutationResult) => {
                            const query = cache.readQuery({
                              query: LIST_TASKS,
                              variables: {
                                includeUnpublished: true,
                              },
                            });

                            const taskData = {
                              libraryTasks: [mutationResult.data.removeTask, ...query.libraryTasks],
                            };

                            cache.writeQuery({
                              query: LIST_TASKS,
                              data: taskData,
                              variables: {
                                includeUnpublished: true,
                              },
                            });
                          }}
                          onError={(data) => {
                            message.error(`Sorry, an error occurred`, 4);
                          }}
                          onCompleted={(data) => {
                            message.success(`the task has been deleted successfully`, 4);
                            this.setState({
                              redirectAfterTaskDel: true,
                            });
                          }}
                        >
                          {(removeTask, { loading, error, data }) => {
                            const confirmRemoveTask = () => {
                              Modal.confirm({
                                title: 'Remove task',
                                icon: <ExclamationCircleOutlined />,
                                content: `Are you sure you want to remove this task?`,
                                centered: true,
                                okText: 'Remove',
                                cancelText: 'Cancel',
                                onOk: removeTask,
                              });
                            };
                            return (
                              <Button
                                type="link"
                                className="p-0"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.setState({ popoverVisible: false });
                                  confirmRemoveTask();
                                }}
                              >
                                Remove task
                              </Button>
                            );
                          }}
                        </Mutation>
                      </>
                    }
                  >
                    <Button className="task-details-header__more-btn" type="link">
                      <MoreOutlined style={{ fontSize: 20 }} />
                    </Button>
                  </Popover>
                </div>

                {data.task.description && (
                  <div className="task-details__desc">
                    <span className="task-details__desc-heading">
                      <IconDescription className="task-details__desc-icon" />
                      <b>Description</b>
                    </span>
                    <p className="u-whitespace-prewrap">{data.task.description}</p>
                  </div>
                )}
                {data.task.officialAdvice && (
                  <div className="task-details__advice">
                    <span className="task-details__advice-heading">
                      <IconAdvice className="task-details__advice-icon" />
                      <b>Official advice</b>
                    </span>
                    <p className="u-whitespace-prewrap">{data.task.officialAdvice}</p>
                  </div>
                )}

                <div className="task-details-meta-wrapper">
                  <div className="task-details-meta">
                    <Tooltip title="Matter types">
                      <IconMatters className="task-details-meta__icon" />
                    </Tooltip>
                    <div className="task-details-meta__tags">
                      {data.task.matterTypes.length === 0 && (
                        <Tag className="u-lowercase" style={{ margin: '0 5px 3px 0' }}>
                          Any
                        </Tag>
                      )}
                      {data.task.matterTypes.sort().map((matterType) => (
                        <Tag key={matterType} className="u-lowercase task-details-meta__tag">
                          {matterType}
                        </Tag>
                      ))}
                    </div>
                  </div>

                  <div className="task-details-meta">
                    <Tooltip title="Published status">
                      <IconTaskPublishedStatus className="task-details-meta__icon" />
                    </Tooltip>
                    <div className="task-details-meta__tags">
                      <Tag className="u-lowercase task-details-meta__tag">
                        {data.task.isPublished ? 'published' : 'unpublished'}
                      </Tag>
                    </div>
                  </div>
                </div>

                {/* Output tasks to page */}
                {this.createSections(data.task)}

                {/* Add task section modal */}
                <Modal
                  title="Add task section"
                  centered
                  destroyOnClose={true}
                  open={this.state.addTaskSectionModalVisible}
                  onCancel={this.addTaskSectionModalVisible}
                  footer={null}
                  width="500px"
                  maskClosable={false}
                  closeIcon={<IconModalClose />}
                >
                  <AddTaskSectionForm
                    addTaskSectionModalVisible={this.addTaskSectionModalVisible}
                    taskId={data.task.id}
                  />
                </Modal>

                {/* Rename task modal */}
                <Modal
                  title="Rename task"
                  centered
                  destroyOnClose={true}
                  open={this.state.renameTaskModalVisible}
                  onCancel={this.renameTaskModalVisible}
                  footer={null}
                  width="500px"
                  maskClosable={false}
                  closeIcon={<IconModalClose />}
                >
                  <RenameTaskForm
                    renameTaskModalVisible={this.renameTaskModalVisible}
                    taskId={data.task.id}
                    taskName={data.task.name}
                  />
                </Modal>

                {/* Update task description modal */}
                <Modal
                  title="Update task description"
                  centered
                  destroyOnClose={true}
                  open={this.state.updateTaskDescModalVisible}
                  onCancel={this.updateTaskDescModalVisible}
                  footer={null}
                  width="500px"
                  maskClosable={false}
                  closeIcon={<IconModalClose />}
                >
                  <UpdateTaskDescForm
                    updateTaskDescModalVisible={this.updateTaskDescModalVisible}
                    taskId={data.task.id}
                    taskDescription={data.task.description}
                  />
                </Modal>

                {/* Update task official advice modal */}
                <Modal
                  title="Task official advice"
                  centered
                  destroyOnClose={true}
                  open={this.state.updateTaskOfficialAdviceModalVisible}
                  onCancel={this.updateTaskOfficialAdviceModalVisible}
                  footer={null}
                  width="500px"
                  maskClosable={false}
                  closeIcon={<IconModalClose />}
                >
                  <UpdateTaskOfficialAdviceForm
                    updateTaskOfficialAdviceModalVisible={this.updateTaskOfficialAdviceModalVisible}
                    taskId={data.task.id}
                    officialAdvice={data.task.officialAdvice}
                  />
                </Modal>

                {/* Change task matter types modal */}
                <Modal
                  title="Change matter types"
                  centered
                  destroyOnClose={true}
                  open={this.state.changeMatterTypesModalVisible}
                  onCancel={this.changeMatterTypesModalVisible}
                  footer={null}
                  width="500px"
                  maskClosable={false}
                  closeIcon={<IconModalClose />}
                >
                  <ChangeTaskMatterTypesForm
                    changeMatterTypesModalVisible={this.changeMatterTypesModalVisible}
                    taskId={data.task.id}
                    matterTypes={data.task.matterTypes}
                    matterTypeValues={data.__type}
                  />
                </Modal>
              </>
            );
          }}
        </Query>
      );
    }
  }
}

export default TaskDetails;
