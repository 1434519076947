import React, { PureComponent } from 'react';
import { Mutation } from '@apollo/react-components';
import { Button, Form, Input, Checkbox, Radio, message } from 'antd/es';
import { PREPARE_TASK } from '../../graphql/tasks/prepare-task';
import { CHANGE_TASK_ADVICE } from '../../graphql/tasks/change-task-advice';
import { LIST_TASKS } from '../../graphql/tasks/list-tasks';
import './Forms.scss';

class AddTaskForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      matterTypesVisible: false,
    };
  }

  saveValuesToState = (val) => {
    this.setState(val);
  };

  onMatterTypesRadioChange = (e) => {
    this.setState({
      matterTypesVisible: e.target.value,
    });
  };

  outputMatterTypes = () => {
    const matterTypesArr = this.props.data.__type.enumValues.map((val) => {
      return val.name;
    });

    return matterTypesArr.sort().map((matterType) => (
      <Checkbox key={matterType} value={matterType}>
        {matterType}
      </Checkbox>
    ));
  };

  render() {
    // Set up form
    const layout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    };

    return (
      <Mutation
        mutation={CHANGE_TASK_ADVICE}
        onError={(err) => {
          message.error(`Sorry, an error occurred`, 4);
        }}
        onCompleted={(data) => {
          const { name } = data.changeTaskAdvice;

          message.success(
            <span className="u-standardcase">{`'${name}' has been added successfully`}</span>,
            4
          );

          /* Close modal */
          this.props.addTaskModalVisible(false);
        }}
      >
        {(changeAdvice, { loading: adviceLoading }) => {
          return (
            <Mutation
              mutation={PREPARE_TASK}
              update={(cache, mutationResult) => {
                const query = cache.readQuery({
                  query: LIST_TASKS,
                  variables: {
                    includeUnpublished: true,
                  },
                });

                const taskData = {
                  libraryTasks: [mutationResult.data.prepareLibraryTask, ...query.libraryTasks],
                  __type: { ...query.__type },
                };

                cache.writeQuery({
                  query: LIST_TASKS,
                  data: taskData,
                  variables: {
                    includeUnpublished: true,
                  },
                });
              }}
              onError={(data) => {
                message.error(`Sorry, an error occurred`, 4);
              }}
            >
              {(addTask, { loading: addTaskLoading }) => {
                return (
                  <Form
                    requiredMark={false}
                    {...layout}
                    name="AddTaskForm"
                    className="add-task-form"
                    layout="vertical"
                    onFinish={async (values) => {
                      const data = await addTask({
                        variables: {
                          input: {
                            name: values.taskName,
                            description: values.taskDescription,
                            matterTypes: values.matterTypes,
                          },
                        },
                      });
                      data &&
                        changeAdvice({
                          variables: {
                            input: {
                              taskId: data.data.prepareLibraryTask.id,
                              officialAdvice: values.officialAdvice,
                            },
                          },
                        });
                    }}
                    onValuesChange={this.saveValuesToState}
                  >
                    <Form.Item
                      label="Name"
                      name="taskName"
                      validateTrigger={['onBlur', 'onChange']}
                      rules={[
                        {
                          validator: (_, value) => {
                            if (!value) {
                              return Promise.reject('Name is required');
                            }

                            if (value.length > 250) {
                              return Promise.reject('Name must be between 1 and 250 characters');
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Description"
                      name="taskDescription"
                      validateTrigger={['onBlur', 'onChange']}
                      rules={[
                        {
                          validator: (_, value) => {
                            if (value && value.length > 5000) {
                              return Promise.reject(
                                'Description must be between 1 and 5000 characters'
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input.TextArea rows="5" />
                    </Form.Item>
                    <Form.Item
                      label="Official advice"
                      name="officialAdvice"
                      validateTrigger={['onBlur', 'onChange']}
                      rules={[
                        {
                          validator: (_, value) => {
                            if (value && value.length > 5000) {
                              return Promise.reject(
                                'Official advice must be between 1 and 5000 characters'
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input.TextArea rows="5" />
                    </Form.Item>
                    <Form.Item label="Matter Types" className="mb-0">
                      <Radio.Group
                        onChange={this.onMatterTypesRadioChange}
                        value={this.state.matterTypesVisible}
                      >
                        <Radio value={false}>Any</Radio>
                        <Radio value={true}>Specific</Radio>
                      </Radio.Group>
                    </Form.Item>
                    {this.state.matterTypesVisible && (
                      <Form.Item
                        name="matterTypes"
                        className="checkbox-group-highlight mb-0 mt-10"
                        validateTrigger={['onBlur']}
                        rules={[
                          {
                            required: true,
                            message: 'You need to select at least one matter type',
                          },
                        ]}
                      >
                        <Checkbox.Group>{this.outputMatterTypes()}</Checkbox.Group>
                      </Form.Item>
                    )}
                    <Form.Item
                      name="submitButton"
                      className="u-text-center"
                      wrapperCol={{ ...layout.wrapperCol, offset: 0 }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={addTaskLoading || adviceLoading}
                      >
                        {addTaskLoading || adviceLoading ? 'Adding task' : 'Add task'}
                      </Button>
                    </Form.Item>
                  </Form>
                );
              }}
            </Mutation>
          );
        }}
      </Mutation>
    );
  }
}

export default AddTaskForm;
