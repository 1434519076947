import React, { PureComponent } from 'react';
import { Mutation } from '@apollo/react-components';
import { Button, Form, Input, Select, message } from 'antd/es';
import { ADD_FIRM_MEMBER } from '../../graphql/firms/add-firm-member';
import { LIST_FIRM_MEMBERS } from '../../graphql/firms/list-firm-members';
import { GET_FIRM_DETAILS } from '../../graphql/firms/get-firm-details';
import './Forms.scss';

class AddFirmMemberForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      professionalType: '',
    };
  }

  saveValuesToState = (val) => {
    this.setState(val);
  };

  render() {
    // Set up form
    const layout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    };

    const { Option } = Select;

    return (
      <Mutation
        mutation={ADD_FIRM_MEMBER}
        update={(cache, mutationResult) => {
          // Updating firm members
          const listFirmMembersQuery = cache.readQuery({
            query: LIST_FIRM_MEMBERS,
            variables: {
              firmId: this.props.firmId,
            },
          });

          const firmMembersData = {
            firmMembers: [mutationResult.data.addFirmMember, ...listFirmMembersQuery.firmMembers],
          };

          cache.writeQuery({
            query: LIST_FIRM_MEMBERS,
            variables: {
              firmId: this.props.firmId,
            },
            data: firmMembersData,
          });

          // Updating seats
          const getFirmDetailsQuery = cache.readQuery({
            query: GET_FIRM_DETAILS,
            variables: {
              firmId: this.props.firmId,
            },
          });

          const firmData = {
            firm: {
              ...getFirmDetailsQuery.firm,
              vacantSeatCount: getFirmDetailsQuery.firm.vacantSeatCount - 1,
              occupiedSeatCount: getFirmDetailsQuery.firm.occupiedSeatCount + 1,
            },
          };

          cache.writeQuery({
            query: GET_FIRM_DETAILS,
            variables: {
              firmId: this.props.firmId,
            },
            data: firmData,
          });
        }}
        onError={(data) => message.error(`Sorry, an error occurred`, 4)}
        onCompleted={(data) => {
          const { preferredName, lastName } = data.addFirmMember.professional;

          message.success(
            <span className="u-standardcase">
              {preferredName} {lastName} has been added successfully
            </span>,
            4
          );

          /* Close modal */
          this.props.addFirmMemberModalVisible();
        }}
      >
        {(addFirmMember, { loading, error, data }) => {
          return (
            <Form
              requiredMark={false}
              {...layout}
              name="AddFirmMemberForm"
              className="add-firm-members-form"
              layout="vertical"
              onValuesChange={this.saveValuesToState}
              onFinish={(values) => {
                if (
                  this.state.professionalType === 'BARRISTER' ||
                  this.state.professionalType === 'SOLICITOR'
                ) {
                  addFirmMember({
                    variables: {
                      input: {
                        professionalType: values.professionalType,
                        firstName: values.firstName,
                        preferredName: values.preferredName,
                        lastName: values.lastName,
                        emailAddress: values.emailAddress,
                        sraNumber: values.sraNumber,
                        role: values.role,
                        firmId: this.props.firmId,
                        employeeNumber: values.employeeNumber,
                        office: values.office,
                        jobTitle: values.jobTitle,
                      },
                    },
                  });
                } else if (this.state.professionalType === 'PARALEGAL') {
                  addFirmMember({
                    variables: {
                      input: {
                        professionalType: values.professionalType,
                        firstName: values.firstName,
                        preferredName: values.preferredName,
                        lastName: values.lastName,
                        emailAddress: values.emailAddress,
                        paralegalNumber: values.paralegalNumber,
                        role: values.role,
                        firmId: this.props.firmId,
                        employeeNumber: values.employeeNumber,
                        office: values.office,
                        jobTitle: values.jobTitle,
                      },
                    },
                  });
                } else if (this.state.professionalType === 'SUPPORT') {
                  addFirmMember({
                    variables: {
                      input: {
                        professionalType: values.professionalType,
                        firstName: values.firstName,
                        preferredName: values.preferredName,
                        lastName: values.lastName,
                        emailAddress: values.emailAddress,
                        role: values.role,
                        firmId: this.props.firmId,
                        employeeNumber: values.employeeNumber,
                        office: values.office,
                        jobTitle: values.jobTitle,
                      },
                    },
                  });
                }
              }}
            >
              <Form.Item
                label="Firm member type"
                name="professionalType"
                validateTrigger={['onBlur']}
                rules={[{ required: true, message: 'Firm member type is required' }]}
              >
                <Select placeholder="select member type">
                  {this.props.firmMemberTypes.map((type, index) => {
                    return (
                      <Option key={type.name + index} value={type.name}>
                        {type.name.replace(/_/g, ' ').toLowerCase()}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="Role"
                name="role"
                rules={[
                  {
                    required: true,
                    message: 'Role is required',
                  },
                ]}
              >
                <Select placeholder="select user role">
                  {this.props.firmMemberRoles.map((role, index) => {
                    return (
                      <Option key={role.name + index} value={role.name}>
                        {role.name.replace(/_/g, ' ').toLowerCase()}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                label="First name"
                name="firstName"
                validateTrigger={['onBlur']}
                rules={[{ required: true, message: 'First name is required' }]}
              >
                <Input spellCheck="false" />
              </Form.Item>
              <Form.Item
                label="Preferred first name"
                name="preferredName"
                validateTrigger={['onBlur']}
              >
                <Input spellCheck="false" />
              </Form.Item>
              <Form.Item
                label="Last name"
                name="lastName"
                validateTrigger={['onBlur']}
                rules={[{ required: true, message: 'Last name is required' }]}
              >
                <Input spellCheck="false" />
              </Form.Item>
              <Form.Item
                label="Email address"
                name="emailAddress"
                validateTrigger={['onBlur']}
                rules={[
                  {
                    type: 'email',
                    message: 'Invalid email address',
                  },
                  {
                    required: true,
                    message: 'Email address is required',
                  },
                ]}
              >
                <Input type="email" spellCheck="false" />
              </Form.Item>
              {this.state.professionalType === 'BARRISTER' ||
              this.state.professionalType === 'SOLICITOR' ? (
                <Form.Item
                  label="SRA ID number"
                  name="sraNumber"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject('SRA ID number is required');
                        }

                        if (!value.match(/^[0-9]+$/)) {
                          return Promise.reject('SRA ID number must only include numbers');
                        }

                        if (value.length < 5 || value.length > 6) {
                          return Promise.reject('SRA ID number must be 5 or 6 digits');
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input spellCheck="false" />
                </Form.Item>
              ) : null}
              {this.state.professionalType === 'PARALEGAL' ? (
                <Form.Item
                  label="Paralegal number"
                  name="paralegalNumber"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject('Paralegal number is required');
                        }

                        // if (!value.match(/^[0-9]+$/)) {
                        //   return Promise.reject('SRA ID number must only include numbers');
                        // }

                        if (value.length < 1 || value.length > 15) {
                          return Promise.reject(
                            'Paralegal number must be between 1 or 15 characters'
                          );
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input spellCheck="false" />
                </Form.Item>
              ) : null}
              <Form.Item label="Employee number" name="employeeNumber" validateTrigger={['onBlur']}>
                <Input spellCheck="false" />
              </Form.Item>
              <Form.Item label="Office" name="office" validateTrigger={['onBlur']}>
                <Input spellCheck="false" />
              </Form.Item>
              <Form.Item label="Job title" name="jobTitle" validateTrigger={['onBlur']}>
                <Input spellCheck="false" />
              </Form.Item>
              <Form.Item
                name="submitButton"
                className="u-text-center"
                wrapperCol={{ ...layout.wrapperCol, offset: 0 }}
              >
                <Button type="primary" htmlType="submit" loading={loading}>
                  {loading ? 'Adding firm member' : 'Add firm member'}
                </Button>
              </Form.Item>
            </Form>
          );
        }}
      </Mutation>
    );
  }
}

export default AddFirmMemberForm;
