import React, { PureComponent } from 'react';
import { Mutation } from '@apollo/react-components';
import { Button, Form, Input, message } from 'antd/es';
import { DESCRIBE_TASK } from '../../graphql/tasks/describe-task';
import './Forms.scss';

class UpdateTaskDescForm extends PureComponent {
  render() {
    // Set up form
    const layout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    };

    return (
      <Mutation
        mutation={DESCRIBE_TASK}
        onError={(data) => {
          message.error(`Sorry, an error occurred`, 4);
        }}
        onCompleted={(data) => {
          message.success(`The task description has been updated successfully`, 4);

          /* Close modal */
          this.props.updateTaskDescModalVisible();
        }}
      >
        {(updateTaskDesc, { loading, error, data }) => {
          return (
            <Form
              {...layout}
              name="UpdateTaskDescForm"
              className="update-task-desc-form"
              layout="vertical"
              onFinish={(values) => {
                updateTaskDesc({
                  variables: {
                    input: {
                      taskId: this.props.taskId,
                      description: values.taskDesc,
                    },
                  },
                });
              }}
              initialValues={{
                taskDesc: this.props.taskDescription,
              }}
            >
              <Form.Item
                label="Task description"
                name="taskDesc"
                validateTrigger={['onBlur', 'onChange']}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && value.length > 5000) {
                        return Promise.reject(
                          'Task description must be between 1 and 5000 characters'
                        );
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input.TextArea rows="5" />
              </Form.Item>
              <Form.Item
                name="submitButton"
                className="u-text-center"
                wrapperCol={{ ...layout.wrapperCol, offset: 0 }}
              >
                <Button type="primary" htmlType="submit" loading={loading}>
                  {loading ? 'Updating description' : 'Update description'}
                </Button>
              </Form.Item>
            </Form>
          );
        }}
      </Mutation>
    );
  }
}

export default UpdateTaskDescForm;
