import React, { PureComponent } from 'react';
import { ApolloConsumer } from '@apollo/react-components';
import { Mutation } from '@apollo/react-components';
import { Drawer, Button } from 'antd/es';
import Notification from './Notification';
import { ReactComponent as IconNotifications } from '../../assets/images/icon-notifications.svg';
import { ReactComponent as IconModalClose } from '../../assets/images/icon-modal-close.svg';
import { LIST_NOTIFICATIONS } from '../../graphql/notifications/list-notifications';
import { MARK_ALL_NOTIFICATIONS_READ } from '../../graphql/notifications//mark-all-notifications-read';
import './NotificationDrawer.scss';

class NotificationDrawer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      loadingMore: false,
    };
  }

  outputNotifications = (notificationsData) => {
    const notificationsOutput = notificationsData.map((data, index) => {
      return (
        <Notification
          key={data.id}
          id={data.id}
          type={data.type}
          title={data.title}
          body={data.body}
          isRead={data.isRead}
          occurredOn={data.occurredOn}
          path={data.path}
          closeDrawer={this.props.onClose}
        />
      );
    });
    return notificationsOutput;
  };

  render() {
    return (
      <Drawer
        className="notification-drawer"
        title={
          <>
            <IconNotifications className="notification-drawer__header-icon" />
            <span>Notifications</span>
          </>
        }
        placement="right"
        closable={true}
        onClose={this.props.onClose}
        visible={this.props.visible}
        closeIcon={<IconModalClose />}
      >
        <Mutation
          mutation={MARK_ALL_NOTIFICATIONS_READ}
          update={(cache) => {
            const query = cache.readQuery({
              query: LIST_NOTIFICATIONS,
              variables: {
                page: 1,
              },
            });

            const notificationsData = {
              notifications: {
                ...query.notifications,
                totalUnread: 0,
                notifications: [
                  ...query.notifications.notifications.map((val) => {
                    return { ...val, isRead: true };
                  }),
                ],
              },
            };

            cache.writeQuery({
              query: LIST_NOTIFICATIONS,
              variables: {
                page: 1,
              },
              data: notificationsData,
            });
          }}
        >
          {(markAllasRead, { loading, error, data }) => (
            <div className="notification-drawer__mark-all">
              <Button
                type="link"
                className="notification-drawer__mark-all-btn"
                onClick={markAllasRead}
                loading={loading}
                disabled={this.props.totalUnread === 0}
              >
                {loading ? 'Marking all as read' : 'Mark all as read'}
              </Button>
            </div>
          )}
        </Mutation>

        {this.outputNotifications(this.props.notifications)}

        {this.props.hasMorePages ? (
          <ApolloConsumer>
            {(client) => (
              <div className="u-flex-center-both">
                <Button
                  type="link"
                  className="notification-drawer__load-more-btn"
                  onClick={async () => {
                    this.setState({ loadingMore: true });

                    const { data } = await client.query({
                      query: LIST_NOTIFICATIONS,
                      variables: {
                        page: this.state.page + 1,
                      },
                      fetchPolicy: 'network-only',
                    });

                    this.setState({ loadingMore: false });

                    this.setState((prevState) => {
                      return {
                        page: prevState.page + 1,
                      };
                    });

                    const query = client.readQuery({
                      query: LIST_NOTIFICATIONS,
                      variables: {
                        page: 1,
                      },
                    });

                    const notifications = [
                      ...query.notifications.notifications,
                      ...data.notifications.notifications,
                    ];

                    const notificationsDeDuped = Array.from(
                      new Set(notifications.map((a) => a.id))
                    ).map((id) => {
                      return notifications.find((a) => a.id === id);
                    });

                    const notificationsData = {
                      notifications: {
                        ...data.notifications,
                        notifications: notificationsDeDuped,
                      },
                    };

                    client.writeQuery({
                      query: LIST_NOTIFICATIONS,
                      variables: {
                        page: 1,
                      },
                      data: notificationsData,
                    });
                  }}
                  loading={this.state.loadingMore}
                >
                  {this.state.loadingMore ? 'Loading more' : 'Load more'}
                </Button>
              </div>
            )}
          </ApolloConsumer>
        ) : null}
      </Drawer>
    );
  }
}

export default NotificationDrawer;
