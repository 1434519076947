import React, { PureComponent } from 'react';
import { Mutation } from '@apollo/react-components';
import { Button, Form, Checkbox, Radio, message } from 'antd/es';
import { CHANGE_TASK_MATTER_TYPES } from '../../graphql/tasks/change-task-matter-types';
import './Forms.scss';

class ChangeTaskMatterTypes extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      matterTypesVisible: this.props.matterTypes.length > 0 ? true : false,
    };
  }

  saveValuesToState = (val) => {
    this.setState(val);
  };

  onMatterTypesRadioChange = (e) => {
    this.setState({
      matterTypesVisible: e.target.value,
    });
  };

  outputMatterTypes = () => {
    const matterTypesArr = this.props.matterTypeValues.enumValues.map((val) => {
      return val.name;
    });

    return matterTypesArr.sort().map((matterType) => (
      <Checkbox key={matterType} value={matterType}>
        {matterType}
      </Checkbox>
    ));
  };

  render() {
    // Set up form
    const layout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    };

    // Create array of initial matter type values
    const matterTypeInitialValues = this.props.matterTypes.map((matterType) => {
      return matterType;
    });

    return (
      <Mutation
        mutation={CHANGE_TASK_MATTER_TYPES}
        onError={(data) => {
          message.error(`Sorry, an error occurred`, 4);
        }}
        onCompleted={(data) => {
          message.success(`Task matter types have been changed successfully`, 4);

          /* Close modal */
          this.props.changeMatterTypesModalVisible(false);
        }}
      >
        {(changeTaskMatterTypes, { loading, error, data }) => {
          return (
            <Form
              requiredMark={false}
              {...layout}
              name="ChangeTaskMatterTypes"
              className="add-task-form"
              layout="vertical"
              initialValues={{
                matterTypes: matterTypeInitialValues,
              }}
              onFinish={(values) => {
                changeTaskMatterTypes({
                  variables: {
                    input: {
                      taskId: this.props.taskId,
                      matterTypes: values.matterTypes,
                    },
                  },
                });
              }}
              onValuesChange={this.saveValuesToState}
            >
              <Form.Item label="Matter Types" className="mb-0">
                <Radio.Group
                  onChange={this.onMatterTypesRadioChange}
                  value={this.state.matterTypesVisible}
                >
                  <Radio value={false}>Any</Radio>
                  <Radio value={true}>Specific</Radio>
                </Radio.Group>
              </Form.Item>
              {this.state.matterTypesVisible && (
                <Form.Item
                  name="matterTypes"
                  className="checkbox-group-highlight mt-20 mb-0"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      required: true,
                      message: 'You need to select at least one matter type',
                    },
                  ]}
                >
                  <Checkbox.Group>{this.outputMatterTypes()}</Checkbox.Group>
                </Form.Item>
              )}
              <Form.Item
                name="submitButton"
                className="u-text-center"
                wrapperCol={{ ...layout.wrapperCol, offset: 0 }}
              >
                <Button type="primary" htmlType="submit" loading={loading}>
                  {loading ? 'Changing matter types' : 'Change matter types'}
                </Button>
              </Form.Item>
            </Form>
          );
        }}
      </Mutation>
    );
  }
}

export default ChangeTaskMatterTypes;
