import React, { PureComponent } from 'react';
import { Form, Input, InputNumber, Checkbox } from 'antd/es';
import './../Forms.scss';

class FilePart extends PureComponent {
  constructor(props) {
    super(props);

    /* Set some form defaults (workaround) */
    const values = props.form.getFieldsValue();
    if (values?.parts) {
      props.form.setFieldsValue({
        ...values,
        parts: values.parts.map((part, index) => {
          if (index === props.field.name && !props.isList) {
            return {
              ...part,
              configuration: {
                ...part.configuration,
                isRequired: true,
              },
            };
          }
          return part;
        }),
      });
    }
  }

  render() {
    return (
      <div className="task-question-part-content">
        <Form.Item
          name={[this.props.field.name, 'label']}
          label="Question part heading / label"
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject('Question part heading / label is required');
                }

                if (value.length > 250) {
                  return Promise.reject('Must be between 1 and 250 characters');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="Provide a label for the question part e.g. 'First name'" />
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'instructions']}
          label="Instructions"
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (value && value.length > 5000) {
                  return Promise.reject('Must be between 1 and 5000 characters');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.TextArea
            placeholder="Type the instructions for the question part here e.g. 'You must give one answer'"
            rows={3}
          />
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'officialAdvice']}
          label="Official advice for the question part"
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (value && value.length > 5000) {
                  return Promise.reject('Must be between 1 and 5000 characters');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.TextArea
            placeholder="Provide the official advice for the question part"
            rows={3}
          />
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'configuration', 'isRequired']}
          valuePropName="checked"
        >
          <Checkbox>Is an answer required?</Checkbox>
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'configuration', 'maximumFiles']}
          label="Maximum number of files"
          wrapperCol={{ span: 12 }}
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject('Maximum number of files is required');
                }

                if (!Number.isInteger(value)) {
                  return Promise.reject('Maximum number of files must be an integer');
                }

                if (value <= 0 || value > 10) {
                  return Promise.reject('Maximum number of files must be between 1 and 10');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <InputNumber placeholder="Maximum number" style={{ width: '100%' }} min={1} max={10} />
        </Form.Item>
      </div>
    );
  }
}

export default FilePart;
