import React, { PureComponent } from 'react';
import { Mutation } from '@apollo/react-components';
import { Button, Form, Input, message } from 'antd/es';
import { DEFINE_TASK_SECTION } from '../../graphql/tasks/define-task-section';
import { CHANGE_TASK_SECTION_ADVICE } from '../../graphql/tasks/change-task-section-advice';
import './Forms.scss';

class AddTaskSectionForm extends PureComponent {
  render() {
    // Set up form
    const layout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    };

    return (
      <Mutation
        mutation={CHANGE_TASK_SECTION_ADVICE}
        onError={(err) => {
          message.error(`Sorry, an error occurred`, 4);
        }}
        onCompleted={(data) => {
          message.success(`The section has been added successfully`, 4);

          /* Close modal */
          this.props.addTaskSectionModalVisible();
        }}
      >
        {(changeSectionAdvice, { loading: adviceLoading }) => {
          return (
            <Mutation
              mutation={DEFINE_TASK_SECTION}
              onError={(err) => {
                message.error(`Sorry, an error occurred`, 4);
              }}
            >
              {(addTaskSection, { loading: addSectionLoading }) => {
                return (
                  <Form
                    {...layout}
                    name="AddTaskSectionForm"
                    className="add-task-section-form"
                    layout="vertical"
                    onFinish={async (values) => {
                      const data = await addTaskSection({
                        variables: {
                          input: {
                            taskId: this.props.taskId,
                            sectionName: values.sectionName,
                            sectionDescription: values.sectionDescription,
                          },
                        },
                      });
                      data &&
                        changeSectionAdvice({
                          variables: {
                            input: {
                              taskId: this.props.taskId,
                              sectionId:
                                data.data.defineTaskSection.sections[
                                  data.data.defineTaskSection.sections.length - 1
                                ].id,
                              officialAdvice: values.officialAdvice,
                            },
                          },
                        });
                    }}
                  >
                    <Form.Item
                      label="Section name"
                      name="sectionName"
                      validateTrigger={['onBlur', 'onChange']}
                      rules={[
                        {
                          validator: (_, value) => {
                            if (!value) {
                              return Promise.reject('Section name is required');
                            }

                            if (value.length > 250) {
                              return Promise.reject(
                                'Section name must be between 1 and 250 characters'
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Section description"
                      name="sectionDescription"
                      validateTrigger={['onBlur', 'onChange']}
                      rules={[
                        {
                          validator: (_, value) => {
                            if (value && value.length > 5000) {
                              return Promise.reject(
                                'Section description ame must be between 1 and 5000 characters'
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input.TextArea rows="5" />
                    </Form.Item>
                    <Form.Item
                      label="Official advice"
                      name="officialAdvice"
                      validateTrigger={['onBlur', 'onChange']}
                      rules={[
                        {
                          validator: (_, value) => {
                            if (value && value.length > 5000) {
                              return Promise.reject('Name must be between 1 and 5000 characters');
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input.TextArea rows="5" />
                    </Form.Item>
                    <Form.Item
                      name="submitButton"
                      className="u-text-center"
                      wrapperCol={{ ...layout.wrapperCol, offset: 0 }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={addSectionLoading || adviceLoading}
                      >
                        {addSectionLoading || adviceLoading ? 'Adding section' : 'Add section'}
                      </Button>
                    </Form.Item>
                  </Form>
                );
              }}
            </Mutation>
          );
        }}
      </Mutation>
    );
  }
}

export default AddTaskSectionForm;
