import React, { PureComponent } from 'react';
import { Mutation } from '@apollo/react-components';
import jwt_decode from 'jwt-decode';
import { GET_AUTH_TOKEN } from '../../graphql/identity/get-auth-token';
import { Button, Form, Input } from 'antd/es';
import { ReactComponent as Logo } from '../../assets/images/transparently-admin-logo-stacked.svg';
import './LoginPage.scss';
import env from '@beam-australia/react-env';

class LoginPage extends PureComponent {
  constructor() {
    super();
    this.state = {
      showErrorMsg: false,
      errorMsg: '',
    };
  }

  saveValuesToState = (val) => {
    this.setState(val);

    if (this.state.showErrorMsg) {
      this.setState({ showErrorMsg: false });
    }
  };

  render() {
    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { span: 24 },
    };

    return (
      <div className="login-page">
        <Logo className="login-page__logo" />
        <Mutation
          mutation={GET_AUTH_TOKEN}
          onCompleted={(data) => {
            // If authentication is successful, check if the user has the appropriate permissions before proceeding to log in
            if (data.loginWithPassword.successful) {
              const decodedToken = jwt_decode(data.loginWithPassword.accessToken);
              if (decodedToken.permissions.includes('access:administration')) {
                this.props.handleLogin(data);
              } else {
                this.setState({
                  showErrorMsg: true,
                  errorMsg: "you don't have the correct permissions",
                });
              }
            } else {
              this.setState({
                showErrorMsg: true,
                errorMsg: 'incorrect email or password entered',
              });
            }
          }}
        >
          {(login, { loading, error, data }) => {
            return (
              <Form
                requiredMark={false}
                {...layout}
                name="login-form"
                layout="vertical"
                className="login-page__form"
                onFinish={(values) => {
                  login({
                    variables: {
                      input: {
                        clientId: env('AUTH0_CLIENT_ID'),
                        audience: env('AUTH0_AUDIENCE'),
                        scope: 'openid profile',
                        username: this.state.email,
                        password: this.state.password,
                      },
                    },
                  });
                }}
                onValuesChange={this.saveValuesToState}
              >
                <Form.Item
                  label="email"
                  name="email"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      type: 'email',
                      message: 'Invalid email address',
                    },
                    {
                      required: true,
                      message: 'Email address is required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="password"
                  name="password"
                  validateTrigger={['onBlur']}
                  rules={[{ required: true, message: 'Password is required' }]}
                >
                  <Input.Password iconRender={(visible) => (visible ? 'hide' : 'show')} />
                </Form.Item>

                {this.state.showErrorMsg && (
                  <div className="login-page__primary-error ant-form-item-has-error">
                    <div className="ant-form-item-explain u-lowercase">{this.state.errorMsg}</div>
                  </div>
                )}

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-page__form-button"
                    loading={loading}
                    disabled={this.state.email && this.state.password ? false : true}
                  >
                    {loading ? 'logging in' : 'log in'}
                  </Button>
                </Form.Item>
              </Form>
            );
          }}
        </Mutation>
        <div className="login-page__gradient-bar"></div>
      </div>
    );
  }
}

export default LoginPage;
