import React, { PureComponent } from 'react';
import Loading from '../Loading';
import { Query, Mutation } from '@apollo/react-components';
import { Button, Form, Input, Select, message } from 'antd/es';
import { UPDATE_FIRM_MEMBER } from '../../graphql/firms/update-firm-member';
import { UPDATE_PROFESSIONAL } from '../../graphql/firms/update-professional';
import { GET_FIRM_MEMBER } from '../../graphql/firms/get-firm-member';
import './Forms.scss';

class UpdateFirmMemberForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      professionalType: '',
    };
  }

  saveValuesToState = (val) => {
    this.setState(val);
  };

  render() {
    // Set up form
    const layout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    };

    const { Option } = Select;

    return (
      <Query
        query={GET_FIRM_MEMBER}
        variables={{
          firmMemberId: this.props.firmMemberId,
        }}
        onCompleted={(data) => {
          this.setState({ professionalType: data.firmMember.professional.type });
        }}
      >
        {({ loading, error, data: firmMemberData }) => {
          if (loading) {
            return <Loading />;
          }

          if (error) return <p>Error :(</p>;

          return (
            <Mutation
              mutation={UPDATE_FIRM_MEMBER}
              onError={(data) => message.error(`Sorry, an error occurred`, 4)}
              onCompleted={(data) => {
                message.success('The firm member has been updated successfully', 4);

                /* Close modal */
                this.props.updateFirmMemberModalVisible();
              }}
            >
              {(updateFirmMember, { loading, error, data: updateFirmMemberData }) => {
                if (error) {
                  console.log(error);
                }

                return (
                  <Mutation mutation={UPDATE_PROFESSIONAL}>
                    {(updateProfessional, { loading, error, data: updateProfessionalData }) => {
                      const {
                        id: professionalId,
                        preferredName,
                        lastName,
                        type,
                        sraNumber,
                        paralegalNumber,
                      } = firmMemberData.firmMember.professional;

                      const { employeeNumber, office, jobTitle, role } = firmMemberData.firmMember;

                      if (error) {
                        console.log(error);
                      }

                      return (
                        <>
                          <p className="ant-modal__alert">
                            You are updating the details of{' '}
                            <strong>
                              <span className="u-standardcase" style={{ display: 'inline-block' }}>
                                {preferredName} {lastName}
                              </span>
                            </strong>
                          </p>
                          <Form
                            {...layout}
                            name="UpdateFirmMemberForm"
                            className="update-firm-members-form"
                            layout="vertical"
                            onValuesChange={this.saveValuesToState}
                            initialValues={{
                              employeeNumber: employeeNumber,
                              office: office,
                              jobTitle: jobTitle,
                              role: role,
                              professionalType: type,
                              sraNumber: sraNumber,
                              paralegalNumber: paralegalNumber,
                            }}
                            onFinish={(values) => {
                              if (
                                this.state.professionalType === 'BARRISTER' ||
                                this.state.professionalType === 'SOLICITOR'
                              ) {
                                updateProfessional({
                                  variables: {
                                    input: {
                                      professionalId: professionalId,
                                      professionalType: values.professionalType,
                                      sraNumber: values.sraNumber,
                                    },
                                  },
                                });
                              } else if (this.state.professionalType === 'PARALEGAL') {
                                updateProfessional({
                                  variables: {
                                    input: {
                                      professionalId: professionalId,
                                      professionalType: values.professionalType,
                                      paralegalNumber: values.paralegalNumber,
                                    },
                                  },
                                });
                              } else if (this.state.professionalType === 'SUPPORT') {
                                updateProfessional({
                                  variables: {
                                    input: {
                                      professionalId: professionalId,
                                      professionalType: values.professionalType,
                                    },
                                  },
                                });
                              }

                              updateFirmMember({
                                variables: {
                                  input: {
                                    firmMemberId: this.props.firmMemberId,
                                    employeeNumber: values.employeeNumber,
                                    office: values.office,
                                    jobTitle: values.jobTitle,
                                    role: values.role,
                                  },
                                },
                              });
                            }}
                          >
                            <Form.Item
                              label="Firm member type"
                              name="professionalType"
                              validateTrigger={['onBlur']}
                              rules={[{ required: true, message: 'Firm member type is required' }]}
                            >
                              <Select placeholder="select member type">
                                {this.props.firmMemberTypes.map((type, index) => {
                                  return (
                                    <Option key={type.name + index} value={type.name}>
                                      {type.name.replace(/_/g, ' ').toLowerCase()}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              label="Role"
                              name="role"
                              rules={[
                                {
                                  required: true,
                                  message: 'Role is required',
                                },
                              ]}
                            >
                              <Select placeholder="select user role">
                                <Option value="ADMINISTRATOR">Administrator</Option>
                                <Option value="MEMBER">Member</Option>
                              </Select>
                            </Form.Item>
                            {this.state.professionalType === 'BARRISTER' ||
                            this.state.professionalType === 'SOLICITOR' ? (
                              <Form.Item
                                label="SRA ID number"
                                name="sraNumber"
                                validateTrigger={['onBlur']}
                                rules={[
                                  {
                                    validator: (_, value) => {
                                      if (!value) {
                                        return Promise.reject('SRA ID number is required');
                                      }

                                      if (!value.match(/^[0-9]+$/)) {
                                        return Promise.reject(
                                          'SRA ID number must only include numbers'
                                        );
                                      }

                                      if (value.length < 5 || value.length > 6) {
                                        return Promise.reject(
                                          'SRA ID number must be 5 or 6 digits'
                                        );
                                      }

                                      return Promise.resolve();
                                    },
                                  },
                                ]}
                              >
                                <Input spellCheck="false" />
                              </Form.Item>
                            ) : null}
                            {this.state.professionalType === 'PARALEGAL' ? (
                              <Form.Item
                                label="Paralegal number"
                                name="paralegalNumber"
                                validateTrigger={['onBlur']}
                                rules={[
                                  {
                                    validator: (_, value) => {
                                      if (!value) {
                                        return Promise.reject('Paralegal number is required');
                                      }

                                      // if (!value.match(/^[0-9]+$/)) {
                                      //   return Promise.reject('SRA ID number must only include numbers');
                                      // }

                                      if (value.length < 1 || value.length > 15) {
                                        return Promise.reject(
                                          'Paralegal number must be between 1 or 15 characters'
                                        );
                                      }

                                      return Promise.resolve();
                                    },
                                  },
                                ]}
                              >
                                <Input spellCheck="false" />
                              </Form.Item>
                            ) : null}
                            <Form.Item
                              label="Employee number"
                              name="employeeNumber"
                              validateTrigger={['onBlur']}
                            >
                              <Input spellCheck="false" />
                            </Form.Item>
                            <Form.Item label="Office" name="office" validateTrigger={['onBlur']}>
                              <Input spellCheck="false" />
                            </Form.Item>
                            <Form.Item
                              label="Job title"
                              name="jobTitle"
                              validateTrigger={['onBlur']}
                            >
                              <Input spellCheck="false" />
                            </Form.Item>
                            <Form.Item
                              name="submitButton"
                              className="u-text-center"
                              wrapperCol={{ ...layout.wrapperCol, offset: 0 }}
                            >
                              <Button type="primary" htmlType="submit" loading={loading}>
                                {loading ? 'Updating firm member' : 'Update firm member'}
                              </Button>
                            </Form.Item>
                          </Form>
                        </>
                      );
                    }}
                  </Mutation>
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default UpdateFirmMemberForm;
