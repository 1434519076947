import React, { PureComponent } from 'react';
import { Form, Select } from 'antd/es';
import { ReactComponent as IconSingleLineText } from '../../assets/images/icon-part-single-line-text.svg';
import { ReactComponent as IconMultiLineText } from '../../assets/images/icon-part-multi-line-text.svg';
import { ReactComponent as IconDate } from '../../assets/images/icon-part-date.svg';
import { ReactComponent as IconBoolean } from '../../assets/images/icon-part-boolean.svg';
import { ReactComponent as IconAddress } from '../../assets/images/icon-part-address.svg';
import { ReactComponent as IconNumber } from '../../assets/images/icon-part-number.svg';
import { ReactComponent as IconPhone } from '../../assets/images/icon-part-phone.svg';
import { ReactComponent as IconPercent } from '../../assets/images/icon-part-percent.svg';
import { ReactComponent as IconMoney } from '../../assets/images/icon-part-money.svg';
import { ReactComponent as IconEmail } from '../../assets/images/icon-part-email.svg';
import { ReactComponent as IconFile } from '../../assets/images/icon-part-file.svg';
import { ReactComponent as IconChoice } from '../../assets/images/icon-part-choice.svg';
import { ReactComponent as IconList } from '../../assets/images/icon-part-list.svg';
import './TaskQuestionPart.scss';

class TaskQuestionPart extends PureComponent {
  render() {
    const { Option } = Select;
    return (
      <Form.Item
        name={[this.props.field.name, 'type']}
        className="mb-0"
        style={{ width: '100%', minWidth: 0 }}
        validateTrigger={['onBlur']}
        rules={[
          {
            required: true,
            message: 'Question part type is required',
          },
        ]}
      >
        <Select
          placeholder="Select a question part type"
          disabled={this.props.partType !== undefined}
        >
          <Option value="STRING">
            <div className="u-flex-align-center">
              <div className="task-question-part task-question-part--string">
                <IconSingleLineText className="task-question-part__icon" />
              </div>
              <div>Single line text</div>
            </div>
          </Option>

          <Option value="TEXT">
            <div className="u-flex-align-center">
              <div className="task-question-part task-question-part--text">
                <IconMultiLineText className="task-question-part__icon" />
              </div>
              <div>Multi line text</div>
            </div>
          </Option>

          <Option value="DATE">
            <div className="u-flex-align-center">
              <div className="task-question-part task-question-part--date">
                <IconDate className="task-question-part__icon" />
              </div>
              <div>Date</div>
            </div>
          </Option>

          {!this.props.isInsideListItem && (
            <Option value="BOOLEAN">
              <div className="u-flex-align-center">
                <div className="task-question-part task-question-part--boolean">
                  <IconBoolean className="task-question-part__icon" />
                </div>
                <div>Yes/no response</div>
              </div>
            </Option>
          )}

          <Option value="ADDRESS">
            <div className="u-flex-align-center">
              <div className="task-question-part task-question-part--address">
                <IconAddress className="task-question-part__icon" />
              </div>
              <div>Address</div>
            </div>
          </Option>

          <Option value="NUMBER">
            <div className="u-flex-align-center">
              <div className="task-question-part task-question-part--number">
                <IconNumber className="task-question-part__icon" />
              </div>
              <div>Number</div>
            </div>
          </Option>

          <Option value="PERCENT">
            <div className="u-flex-align-center">
              <div className="task-question-part task-question-part--percent">
                <IconPercent className="task-question-part__icon" />
              </div>
              <div>Percentage</div>
            </div>
          </Option>

          <Option value="MONEY">
            <div className="u-flex-align-center">
              <div className="task-question-part task-question-part--money">
                <IconMoney className="task-question-part__icon" />
              </div>
              <div>Money</div>
            </div>
          </Option>

          <Option value="EMAIL">
            <div className="u-flex-align-center">
              <div className="task-question-part task-question-part--email">
                <IconEmail className="task-question-part__icon" />
              </div>
              <div>Email address</div>
            </div>
          </Option>

          <Option value="PHONE">
            <div className="u-flex-align-center">
              <div className="task-question-part task-question-part--phone">
                <IconPhone className="task-question-part__icon" />
              </div>
              <div>Phone</div>
            </div>
          </Option>

          {!this.props.isInsideListItem && (
            <Option value="CHOICE">
              <div className="u-flex-align-center">
                <div className="task-question-part task-question-part--choice">
                  <IconChoice className="task-question-part__icon" />
                </div>
                <div>Multiple choice</div>
              </div>
            </Option>
          )}

          {!this.props.isInsideListItem && (
            <Option value="LIST">
              <div className="u-flex-align-center">
                <div className="task-question-part task-question-part--list">
                  <IconList className="task-question-part__icon" />
                </div>
                <div>List</div>
              </div>
            </Option>
          )}

          {!this.props.isInsideListItem && (
            <Option value="FILE">
              <div className="u-flex-align-center">
                <div className="task-question-part task-question-part--file">
                  <IconFile className="task-question-part__icon" />
                </div>
                <div>File selector</div>
              </div>
            </Option>
          )}
        </Select>
      </Form.Item>
    );
  }
}

export default TaskQuestionPart;
