import React, { PureComponent } from 'react';
import { Query } from '@apollo/react-components';
import { PlusOutlined } from '@ant-design/icons';
import Loading from '../Loading';
import { Button, Modal, Table, Tooltip } from 'antd/es';
import { ReactComponent as IconFirms } from '../../assets/images/icon-firms.svg';
import { ReactComponent as IconModalClose } from '../../assets/images/icon-modal-close.svg';
import tpLogoIconOnly from '../../assets/images/transparently-logo-icon-only.svg';
import IconLicences from '../../assets/images/icon-licences.svg';
import IconFirmMembers from '../../assets/images/icon-firm-members.svg';
import IconClients from '../../assets/images/icon-clients.svg';
import { GET_FIRM_LIST } from '../../graphql/firms/get-firm-list';
import FirmRegistrationForm from '../Forms/FirmRegistrationForm';

class FirmListing extends PureComponent {
  constructor() {
    super();
    this.state = {
      registerFirmModalVisible: false,
    };
  }

  registerFirmModalVisible = () => {
    this.setState((prevState) => {
      return { registerFirmModalVisible: !prevState.registerFirmModalVisible };
    });
  };

  render() {
    // Set up table
    const tableColumns = [
      {
        title: <img src={tpLogoIconOnly} alt="" />,
        dataIndex: 'logoUrl',
        width: 80,
        render: (image) => (
          <img src={`${image}`} alt="" height="40" width="40" className="avatar avatar--in-table" />
        ),
        align: 'center',
      },
      {
        title: 'Firm name',
        dataIndex: 'name',
        width: 300,
        sorter: (a, b) => {
          var nameA = a.name.toUpperCase();
          var nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
        defaultSortOrder: 'ascend',
      },
      {
        title: 'Firm type',
        dataIndex: 'type',
        render: (type) => type.replace(/_/g, ' ').toLowerCase(),
        sorter: (a, b) => {
          var nameA = a.type.toUpperCase();
          var nameB = b.type.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
        defaultSortOrder: 'ascend',
      },
      {
        title: 'Company/LLP number',
        dataIndex: 'companyNumber',
        width: 150,
        sorter: (a, b) => {
          var nameA = a.companyNumber.toUpperCase();
          var nameB = b.companyNumber.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
        defaultSortOrder: 'ascend',
      },
      {
        title: 'SRA ID number',
        dataIndex: 'sraNumber',
        width: 150,
        sorter: (a, b) => {
          var nameA = a.sraNumber.toUpperCase();
          var nameB = b.sraNumber.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'tp firm ID',
        dataIndex: 'referenceNumber',
        width: 150,
        sorter: (a, b) => {
          var nameA = a.referenceNumber.toUpperCase();
          var nameB = b.referenceNumber.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
        defaultSortOrder: 'ascend',
      },
      {
        title: (
          <Tooltip title="Licences purchased">
            <img src={IconLicences} alt="" style={{ minWidth: 18 }} width="18" height="18" />
          </Tooltip>
        ),
        dataIndex: 'seatCount',
        width: 50,
        align: 'center',
      },
      {
        title: (
          <Tooltip title="Licences in use">
            <img src={IconFirmMembers} alt="" style={{ minWidth: 18 }} width="18" height="18" />
          </Tooltip>
        ),
        dataIndex: 'occupiedSeatCount',
        width: 50,
        align: 'center',
        render: (text, record) => {
          return text >= record.seatCount ? (
            <span className="listing-key-fact-warning">{text}</span>
          ) : (
            text
          );
        },
      },
      {
        title: (
          <Tooltip title="Number of clients">
            <img src={IconClients} alt="" style={{ minWidth: 18 }} width="18" height="18" />
          </Tooltip>
        ),
        dataIndex: 'openClientAccountCount',
        width: 50,
        align: 'center',
      },
    ];

    return (
      <>
        <Query query={GET_FIRM_LIST}>
          {({ loading, error, data }) => {
            if (loading) {
              return <Loading />;
            }

            if (error) return <p>Error :(</p>;

            return (
              <>
                <div className="listing-header">
                  <h1 className="listing-header__heading">
                    <IconFirms className="listing-header__icon" />
                    Firms
                  </h1>
                  <Button
                    type="primary"
                    id="register-firm-button"
                    className="listing-header__add-btn"
                    onClick={this.registerFirmModalVisible}
                  >
                  <PlusOutlined />
                  Register firm
                </Button>
              </div >

                {/* Firm listing table */ }
                < Table
            className = "firm-listing-table"
            dataSource = { data.firms }
            pagination = {{ pageSize: 10 }
          }
                  columns={tableColumns}
          rowKey={(record) => record.id}
          onRow={(record) => {
            return {
              onClick: () => {
                this.props.history.push(`/firms/${record.id}`);
              },
            };
          }}
                />

          {/* Firm registration modal */}
          <Modal
            title="Firm registration"
            centered
            destroyOnClose={true}
            open={this.state.registerFirmModalVisible}
            onCancel={this.registerFirmModalVisible}
            footer={null}
            width="500px"
            maskClosable={false}
            closeIcon={<IconModalClose />}
          >
            <FirmRegistrationForm
              firmTypes={data.FirmTypes.enumValues}
              registerFirmModalVisible={this.registerFirmModalVisible}
            />
          </Modal>
        </>
        );
          }}
      </Query >
      </>
    );
  }
}

export default FirmListing;
