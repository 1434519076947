import React, { PureComponent } from 'react';
import { Mutation } from '@apollo/react-components';
// import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Tooltip } from 'antd/es';
import { MARK_NOTIFICATION_READ } from '../../graphql/notifications/mark-notification-read';
import { LIST_NOTIFICATIONS } from '../../graphql/notifications/list-notifications';
import { ReactComponent as IconLicences } from '../../assets/images/icon-licences.svg';
import { ReactComponent as IconClientAccount } from '../../assets/images/icon-client-account.svg';
import { ReactComponent as IconFirmMember } from '../../assets/images/icon-firms.svg';
import './Notification.scss';
import moment from 'moment';


class Notification extends PureComponent {
  displayTimeSince = (occurredOn) => {
    moment.updateLocale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: function (number, withoutSuffix) {
          return withoutSuffix ? 'now' : 'a few seconds';
        },
        m: '1m',
        mm: '%dm',
        h: '1h',
        hh: '%dh',
        d: '1d',
        dd: '%dd',
        M: '1mth',
        MM: '%dmth',
        y: '1y',
        yy: '%dy',
      },
    });

    return moment(occurredOn).fromNow(true);
  };

  notificationLink = (type, path) => {
    if (type === 'seatAllocationUsed') {
      return `/firms/${path[0].id}`;
    }
    if (type === 'firmMemberInactive' || type === 'firmMemberAdded') {
      return `/firms/${path[0].id}#firm-members`;
    }
    if (type === 'clientAccountInactive' || type === 'clientAccountSetup') {
      return `/firms/${path[0].id}#firm-clients`;
    }
  };

  render() {
    return (
      <Mutation
        mutation={MARK_NOTIFICATION_READ}
        variables={{
          input: {
            notificationId: this.props.id,
          },
        }}
        update={(cache, mutationResult) => {
          const query = cache.readQuery({
            query: LIST_NOTIFICATIONS,
            variables: {
              page: 1,
            },
          });

          const notificationsData = {
            notifications: {
              ...query.notifications,
              totalUnread: query.notifications.totalUnread - 1,
            },
          };

          cache.writeQuery({
            query: LIST_NOTIFICATIONS,
            variables: {
              page: 1,
            },
            data: notificationsData,
          });
        }}
      >
        {(markNotificationRead, { loading, error, data }) => (
          <div
            className={`notification ${
              !this.props.isRead ? 'notification--unread' : 'notification--read'
            }`}
          >
            <HashLink
              smooth
              to={this.notificationLink(this.props.type, this.props.path)}
              onClick={() => {
                this.props.closeDrawer();
                markNotificationRead();
              }}
            >
              <div className="u-flex">
                <div className="notification__content">
                  <div className="notification__title">{this.props.title}</div>
                  <div className="notification__body">{this.props.body}</div>
                </div>
                <div className="notification__meta">
                  {(this.props.type === 'firmMemberAdded' ||
                    this.props.type === 'firmMemberInactive') && (
                    <IconFirmMember className="notification__icon" />
                  )}
                  {(this.props.type === 'clientAccountSetup' ||
                    this.props.type === 'clientAccountInactive') && (
                    <IconClientAccount className="notification__icon" />
                  )}
                  {this.props.type === 'seatAllocationUsed' && (
                    <IconLicences className="notification__icon" />
                  )}
                  <div className="notification__time">
                    {this.displayTimeSince(this.props.occurredOn)}
                  </div>
                </div>
              </div>
            </HashLink>
            {!this.props.isRead && (
              <Tooltip title="Mark as read" placement="left">
                <div
                  className="notification__mark-read"
                  onClick={(e) => {
                    markNotificationRead();
                    e.stopPropagation();
                  }}
                >
                  <div className="notification__mark-read-dot"></div>
                </div>
              </Tooltip>
            )}
          </div>
        )}
      </Mutation>
    );
  }
}

export default Notification;
