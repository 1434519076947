import React, { PureComponent } from 'react';
import { Form, Input, Checkbox } from 'antd/es';
import './../Forms.scss';

class PhonePart extends PureComponent {
  constructor(props) {
    super(props);

    /* Set some form defaults (workaround) */
    const values = props.form.getFieldsValue();
    if (values?.parts) {
      props.form.setFieldsValue({
        ...values,
        parts: values.parts.map((part, index) => {
          // Check if part is inside a list part and set defaults
          if (props.isList && index === props.parentField.name) {
            return {
              ...part,
              configuration: {
                ...part.configuration,
                attributes: part.configuration.attributes.map((attr, attrIndex) => {
                  if (props.field.name === attrIndex) {
                    return {
                      ...attr,
                      configuration: {
                        ...attr.configuration,
                        isRequired: true,
                      },
                    };
                  }
                  return attr;
                }),
              },
            };
          }

          // Set defaults if part is NOT inside a list part
          if (index === props.field.name && !props.isList) {
            return {
              ...part,
              configuration: {
                ...part.configuration,
                isRequired: true,
              },
            };
          }
          return part;
        }),
      });
    }
  }

  render() {
    return (
      <div className="task-question-part-content">
        <Form.Item
          name={[this.props.field.name, 'label']}
          label="Question part heading / label"
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (!value) {
                  return Promise.reject('Question part heading / label is required');
                }

                if (value.length > 250) {
                  return Promise.reject('Must be between 1 and 250 characters');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder="Provide a label for the question part e.g. 'Telephone number'" />
        </Form.Item>
        <Form.Item
          name={[this.props.field.name, 'instructions']}
          label="Instructions"
          validateTrigger={['onBlur', 'onChange']}
          rules={[
            {
              validator: (_, value) => {
                if (value && value.length > 5000) {
                  return Promise.reject('Must be between 1 and 5000 characters');
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.TextArea
            placeholder="Type the instructions for the question part here e.g. 'You must give one answer'"
            rows={3}
          />
        </Form.Item>
        {!this.props.isList && (
          <Form.Item
            name={[this.props.field.name, 'officialAdvice']}
            label="Official advice for the question part"
            validateTrigger={['onBlur', 'onChange']}
            rules={[
              {
                validator: (_, value) => {
                  if (value && value.length > 5000) {
                    return Promise.reject('Must be between 1 and 5000 characters');
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.TextArea
              placeholder="Provide the official advice for the question part"
              rows={3}
            />
          </Form.Item>
        )}
        <Form.Item
          name={[this.props.field.name, 'configuration', 'isRequired']}
          valuePropName="checked"
        >
          <Checkbox>Is an answer required?</Checkbox>
        </Form.Item>
      </div>
    );
  }
}

export default PhonePart;
