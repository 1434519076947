import React, { PureComponent } from 'react';
import { Prompt } from 'react-router-dom';
import { Mutation } from '@apollo/react-components';
import { UPDATE_FIRM_DETAILS } from '../../graphql/firms/update-firm-details';
import { Form, Input, InputNumber, Button, message } from 'antd/es';
import './Forms.scss';

class FirmDetailsForm extends PureComponent {
  constructor(props) {
    super();
    this.state = {
      firmName: props.data.firm.name,
      sraNumber: props.data.firm.sraNumber,
      seatCount: props.data.firm.seatCount,
      unsavedFormChanges: false,
    };
  }

  formRef = React.createRef();

  onFormReset = () => {
    this.formRef.current.resetFields();
    this.setState({
      unsavedFormChanges: false,
    });
  };

  saveValuesToState = (val) => {
    this.setState(val);
    this.setState({
      unsavedFormChanges: true,
    });
  };

  render() {
    // Set up form
    const layout = {
      labelCol: {
        span: 12,
      },
      wrapperCol: {
        span: 12,
      },
    };

    return (
      <>
        <Prompt
          when={this.state.unsavedFormChanges}
          message="If you leave this page you will LOSE ALL CHANGES, are you sure?"
        ></Prompt>
        <Mutation
          mutation={UPDATE_FIRM_DETAILS}
          onCompleted={(data) => {
            message.success(`Saved changes successfully`, 4);
            this.setState({
              unsavedFormChanges: false,
            });
          }}
        >
          {(updateFirmDetails, { loading, error, data }) => {
            return (
              <Form
                requiredMark={false}
                {...layout}
                ref={this.formRef}
                name="firmDetailsForm"
                className="firm-details-form"
                layout="vertical"
                initialValues={{
                  firmName: this.props.data.firm.name,
                  companyNumber: this.props.data.firm.companyNumber,
                  sraNumber: this.props.data.firm.sraNumber,
                  seatCount: this.props.data.firm.seatCount,
                }}
                onValuesChange={this.saveValuesToState}
                onFinish={(values) => {
                  updateFirmDetails({
                    variables: {
                      input: {
                        id: this.props.data.firm.id,
                        companyNumber: values.companyNumber,
                        sraNumber: values.sraNumber,
                        name: values.firmName,
                        seatCount: values.seatCount,
                      },
                    },
                  });
                }}
              >
                <Form.Item
                  name="firmName"
                  label="Firm name"
                  validateTrigger={['onBlur']}
                  rules={[{ required: true, message: 'firm name is required' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="companyNumber"
                  label="Company/LLP number"
                  validateTrigger={['onBlur']}
                  rules={[
                    { required: true, message: 'Company/LLP number is required' },
                    { min: 8, max: 8, message: 'Company/LLP number must be 8 characters/digits' },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="sraNumber"
                  label="SRA ID number"
                  validateTrigger={['onBlur']}
                  rules={[
                    {
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.reject('SRA ID number is required');
                        }

                        if (!value.match(/^[0-9]+$/)) {
                          return Promise.reject('SRA ID number must only include numbers');
                        }

                        if (value.length < 5 || value.length > 6) {
                          return Promise.reject('SRA ID number must be 5 or 6 digits');
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="seatCount"
                  label="Licences purchased"
                  validateTrigger={['onBlur', 'onChange']}
                  rules={[
                    {
                      required: true,
                      message: `Licences purchased can not be lower than your licences taken`,
                      type: 'number',
                      min: this.props.data.firm.occupiedSeatCount,
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 0 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={!this.state.unsavedFormChanges}
                  >
                    {loading ? 'Saving changes' : 'Save changes'}
                  </Button>
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={this.onFormReset}
                    style={{ marginLeft: 20 }}
                    disabled={!this.state.unsavedFormChanges}
                  >
                    Clear
                  </Button>
                </Form.Item>
              </Form>
            );
          }}
        </Mutation>
      </>
    );
  }
}

export default FirmDetailsForm;
