import React, { PureComponent } from 'react';
import { Query } from '@apollo/react-components';
import Loading from '../Loading';
import { Table, Button, Modal } from 'antd/es';
import { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as IconUsers } from '../../assets/images/icon-users.svg';
import { ReactComponent as IconModalClose } from '../../assets/images/icon-modal-close.svg';
import tpLogoIconOnly from '../../assets/images/transparently-logo-icon-only.svg';
import { GET_USER_LIST } from '../../graphql/users/get-user-list';
import InternalUserRegistrationForm from '../Forms/InternalUserRegistrationForm';

class UserListing extends PureComponent {
  constructor() {
    super();
    this.state = {
      registerInternalUserModalVisible: false,
    };
  }

  registerInternalUserModalVisible = () => {
    this.setState((prevState) => {
      return { registerInternalUserModalVisible: !prevState.registerInternalUserModalVisible };
    });
  };

  render() {
    // Set up table
    const userTable = (userData) => [
      {
        title: <img src={tpLogoIconOnly} alt="" />,
        dataIndex: 'picture',
        width: 80,
        render: (image) => (
          <img src={`${image}`} alt="" height="40" width="40" className="avatar avatar--in-table" />
        ),
        align: 'center',
      },
      {
        title: (
          <>
            Preferred /<br /> first name
          </>
        ),
        dataIndex: 'preferredName',
        width: 150,
        sorter: (a, b) => {
          var nameA = !a.preferredName ? '' : a.preferredName.toUpperCase();
          var nameB = !b.preferredName ? '' : b.preferredName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Last name',
        dataIndex: 'familyName',
        width: 150,
        sorter: (a, b) => {
          var nameA = !a.familyName ? '' : a.familyName.toUpperCase();
          var nameB = !b.familyName ? '' : b.familyName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
        defaultSortOrder: 'ascend',
      },
      {
        title: 'Email address',
        dataIndex: 'email',
        width: 350,
        sorter: (a, b) => {
          var nameA = !a.email ? '' : a.email.toUpperCase();
          var nameB = !b.email ? '' : b.email.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        width: 180,
        sorter: (a, b) => {
          var nameA = !a.phone ? '' : a.phone.toUpperCase();
          var nameB = !b.phone ? '' : b.phone.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'User type',
        dataIndex: 'type',
        render: (type) => type.replace(/_/g, ' ').toLowerCase(),
        filters:
          userData?.UserTypes?.enumValues?.map((type) => ({
            text: type.name.replace(/_/g, ' ').toLowerCase(),
            value: type.name,
          })) ?? [],
        onFilter: (value, record) => record.type === value,
        filterMultiple: true,
      },
      {
        title: 'tp user id',
        dataIndex: 'referenceNumber',
        render: (text) => (text ? text : 'n/a'),
        width: 180,
        sorter: (a, b) => {
          var nameA = !a.referenceNumber ? '' : a.referenceNumber.toUpperCase();
          var nameB = !b.referenceNumber ? '' : b.referenceNumber.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        },
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Status',
        dataIndex: 'blocked',
        width: 150,
        render: (text) => (text ? 'inactive' : 'active'),
        filters: [
          {
            text: 'active',
            value: false,
          },
          {
            text: 'inactive',
            value: true,
          },
        ],
        onFilter: (value, record) => record.blocked === value,
        filterMultiple: false,
      },
    ];

    return (
      <>
        <Query query={GET_USER_LIST}>
          {({ loading, error, data: userData }) => {
            if (loading) {
              return <Loading />;
            }

            if (error) return <p>Error :(</p>;

            return (
              <>
                <div className="listing-header">
                  <h1 className="listing-header__heading">
                    <IconUsers className="listing-header__icon" />
                    Users
                  </h1>
                  <Button
                    type="primary"
                    className="listing-header__add-btn"
                    onClick={this.registerInternalUserModalVisible}
                  >
                    <PlusOutlined />
                    Register internal user
                  </Button>
                </div>

                {/* User listing table */}
                <Table
                  className="user-listing-table"
                  dataSource={userData.users.items}
                  pagination={{ pageSize: 10 }}
                  columns={userTable(userData)}
                  rowKey={(record) => record.id}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        this.props.history.push(`/users/${record.id}`);
                      },
                    };
                  }}
                />

                {/* Internal user registration modal */}
                <Modal
                  title="Internal user registration"
                  centered
                  destroyOnClose={true}
                  open={this.state.registerInternalUserModalVisible}
                  onCancel={this.registerInternalUserModalVisible}
                  footer={null}
                  width="500px"
                  maskClosable={false}
                  closeIcon={<IconModalClose />}
                >
                  <InternalUserRegistrationForm
                    registerInternalUserModalVisible={this.registerInternalUserModalVisible}
                  />
                </Modal>
              </>
            );
          }}
        </Query>
      </>
    );
  }
}

export default UserListing;
