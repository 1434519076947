import React, { PureComponent } from 'react';
import { Mutation } from '@apollo/react-components';
import { Button, Form, InputNumber, message } from 'antd/es';
import { REORDER_TASK_SECTION } from '../../graphql/tasks/reorder-task-section';
import './Forms.scss';

class ReorderTaskSectionForm extends PureComponent {
  render() {
    // Set up form
    const layout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    };

    return (
      <Mutation
        mutation={REORDER_TASK_SECTION}
        onError={(data) => {
          message.error(`Sorry, an error occurred`, 4);
        }}
        onCompleted={(data) => {
          message.success(`The section has been reordered successfully`, 4);

          /* Close modal */
          this.props.reorderTaskSectionModalVisible();
        }}
      >
        {(renameTaskSection, { loading, error, data }) => {
          return (
            <Form
              {...layout}
              name="ReorderTaskSectionForm"
              className="reorder-task-section-form"
              layout="vertical"
              onFinish={(values) => {
                renameTaskSection({
                  variables: {
                    input: {
                      taskId: this.props.taskId,
                      sectionId: this.props.sectionId,
                      ordering: values.ordering,
                    },
                  },
                });
              }}
            >
              <Form.Item
                label={`Section position (total sections: ${this.props.sectionCount})`}
                name="ordering"
                validateTrigger={['onBlur', 'onChange']}
                rules={[
                  {
                    required: true,
                    message: `Section position must be between 1 and ${this.props.sectionCount}`,
                    type: 'number',
                    min: 1,
                    max: this.props.sectionCount,
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                name="submitButton"
                className="u-text-center"
                wrapperCol={{ ...layout.wrapperCol, offset: 0 }}
              >
                <Button type="primary" htmlType="submit" loading={loading}>
                  {loading ? 'Reordering section' : 'Reorder section'}
                </Button>
              </Form.Item>
            </Form>
          );
        }}
      </Mutation>
    );
  }
}

export default ReorderTaskSectionForm;
