import React, { PureComponent } from 'react';
import { Query } from '@apollo/react-components';
import { Link } from 'react-router-dom';
import Loading from '../Loading';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd/es';
import UserDetailsForm from '../Forms/UserDetailsForm';
import { GET_USER } from '../../graphql/users/get-user';
import { LIST_FIRM_MEMBERSHIPS } from '../../graphql/users/list-firm-memberships';
import { LIST_CLIENT_ACCOUNTS_FOR_CLIENT } from '../../graphql/users/list-client-accounts-for-client';
import tpLogoIconOnly from '../../assets/images/transparently-logo-icon-only.svg';
import './UserDetails.scss';

class UserDetails extends PureComponent {
  render() {
    // Set up firm memberships table
    const firmMembershipTable = (firmMembershipsData) => {
      return [
        {
          title: <img src={tpLogoIconOnly} alt="" />,
          dataIndex: ['firm', 'logoUrl'],
          width: '80px',
          render: (image) => (
            <img
              src={`${image}`}
              alt=""
              height="36"
              width="36"
              className="avatar avatar--in-table"
            />
          ),
          align: 'center',
        },
        {
          title: 'Firm name',
          dataIndex: ['firm', 'name'],
          sorter: (a, b) => {
            var nameA = a.firm.name.toUpperCase();
            var nameB = b.firm.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          },
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: 'tp firm ID',
          dataIndex: ['firm', 'referenceNumber'],
          sorter: (a, b) => {
            var nameA = a.firm.referenceNumber.toUpperCase();
            var nameB = b.firm.referenceNumber.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          },
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: 'Firm SRA ID',
          dataIndex: ['firm', 'sraNumber'],
          sorter: (a, b) => {
            var nameA = a.firm.sraNumber.toUpperCase();
            var nameB = b.firm.sraNumber.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          },
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: 'Status',
          dataIndex: 'status',
          render: (status) => status.replace(/_/g, ' ').toLowerCase(),
          filters:
            firmMembershipsData?.firmMemberStatuses?.enumValues?.map((status) => ({
              text: status.name.replace(/_/g, ' ').toLowerCase(),
              value: status.name,
            })) ?? [],
          onFilter: (value, record) => record.status === value,
          filterMultiple: true,
        },
      ];
    };

    // Set up firm accounts table (for clients)
    const clientAccountsForClientTable = (clientAccountsForClientData) => {
      return [
        {
          title: <img src={tpLogoIconOnly} alt="" />,
          dataIndex: ['firm', 'logoUrl'],
          width: '80px',
          render: (image) => (
            <img
              src={`${image}`}
              alt=""
              height="36"
              width="36"
              className="avatar avatar--in-table"
            />
          ),
          align: 'center',
        },
        {
          title: 'Firm name',
          dataIndex: ['firm', 'name'],
          sorter: (a, b) => {
            var nameA = a.firm.name.toUpperCase();
            var nameB = b.firm.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          },
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: 'tp firm ID',
          dataIndex: ['firm', 'referenceNumber'],
          sorter: (a, b) => {
            var nameA = a.firm.referenceNumber.toUpperCase();
            var nameB = b.firm.referenceNumber.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          },
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: 'Firm SRA ID',
          dataIndex: ['firm', 'sraNumber'],
          sorter: (a, b) => {
            var nameA = a.firm.sraNumber.toUpperCase();
            var nameB = b.firm.sraNumber.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          },
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: 'Lead professional',
          dataIndex: ['leadProfessional', 'name'],
          sorter: (a, b) => {
            var nameA = a.leadProfessional.name.toUpperCase();
            var nameB = b.leadProfessional.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          },
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: 'Status',
          dataIndex: 'status',
          render: (status) => status.replace(/_/g, ' ').toLowerCase(),
          filters:
            clientAccountsForClientData?.clientAccountStatuses?.enumValues?.map((status) => ({
              text: status.name.replace(/_/g, ' ').toLowerCase(),
              value: status.name,
            })) ?? [],
          onFilter: (value, record) => record.status === value,
          filterMultiple: true,
        },
      ];
    };

    return (
      <>
        <Query
          query={GET_USER}
          variables={{
            userId: this.props.match.params.id,
          }}
        >
          {({ loading, error, data: userData }) => {
            if (loading) {
              return <Loading />;
            }

            if (error) return <p>Error :(</p>;

            if (userData.user) {
              return (
                <>
                  <Button ghost type="primary" className="back-btn">
                    <Link to="/users">
                      <ArrowLeftOutlined />
                    </Link>
                  </Button>
                  <div className="u-flex-align-center user-details">
                    <img
                      src={userData.user.picture}
                      className="avatar"
                      alt=""
                      width="65"
                      height="65"
                    />

                    <h1 className="user-name">
                      {`${userData.user.preferredName} ${userData.user.familyName}`}
                      <span className="user-name__meta">
                        <Tooltip title="User type">
                          {userData.user.type.replace(/_/g, ' ').toLowerCase()}
                        </Tooltip>
                        {userData.user.referenceNumber ? (
                          <Tooltip title="tp user ID">{userData.user.referenceNumber}</Tooltip>
                        ) : null}
                      </span>
                    </h1>
                  </div>
                  <UserDetailsForm data={userData} />

                  {userData.user.type === 'PROFESSIONAL' ? (
                    <>
                      <h2 className="heading mt-40">Firm memberships</h2>

                      <Query
                        query={LIST_FIRM_MEMBERSHIPS}
                        variables={{
                          professionalId: this.props.match.params.id,
                        }}
                      >
                        {({ loading, error, data: firmMembershipsData }) => {
                          if (loading) {
                            return (
                              <Table
                                loading={{
                                  spinning: loading,
                                  indicator: <Loading small />,
                                }}
                                columns={firmMembershipTable()}
                              />
                            );
                          }

                          if (error) return <p>Error :(</p>;

                          return (
                            <Table
                              className="firm-memberships-table"
                              dataSource={firmMembershipsData.firmMemberships}
                              columns={firmMembershipTable(firmMembershipsData)}
                              rowKey={(record) => record.firm.id}
                              pagination={{ pageSize: 10 }}
                            />
                          );
                        }}
                      </Query>
                    </>
                  ) : null}

                  {userData.user.type === 'CLIENT' ? (
                    <>
                      <h2 className="heading mt-40">Firm accounts</h2>
                      <div className="subheading">Firm(s) instructed by this client</div>

                      <Query
                        query={LIST_CLIENT_ACCOUNTS_FOR_CLIENT}
                        variables={{
                          clientId: this.props.match.params.id,
                        }}
                      >
                        {({ loading, error, data: clientAccountsForClientData }) => {
                          if (loading) {
                            return (
                              <Table
                                loading={{
                                  spinning: loading,
                                  indicator: <Loading small />,
                                }}
                                columns={clientAccountsForClientTable()}
                              />
                            );
                          }

                          if (error) return <p>Error :(</p>;

                          return (
                            <Table
                              className="firm-accounts-table"
                              dataSource={clientAccountsForClientData.clientAccountsForClient.items}
                              columns={clientAccountsForClientTable(clientAccountsForClientData)}
                              rowKey={(record) => {
                                return record.id;
                              }}
                              pagination={{ pageSize: 10 }}
                            />
                          );
                        }}
                      </Query>
                    </>
                  ) : null}
                </>
              );
            } else {
              return <p>sorry, that user doesn't seem to exist.</p>;
            }
          }}
        </Query>
      </>
    );
  }
}

export default UserDetails;
