import React from 'react';
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/browser';
import env from '@beam-australia/react-env';
import App from './components/App';
import { ConfigProvider, theme } from 'antd/es';



if (env('ENVIRONMENT') !== 'development') {
  Sentry.init({
    dsn: env('SENTRY_DSN'),
    release: env('APP_VERSION'),
    environment: env('ENVIRONMENT'),
    debug: true,
  });
}


createRoot(document.getElementById('root')).render(
  <ConfigProvider
    theme={{
      "token": {
        "colorError": "#fcc100",
        "colorPrimary": "#02ace6",
        "colorInfo": "#02ace6",
        "colorTextBase": "#ffffffb3",
        "borderRadius": 5,
        "fontFamily": "Quicksand, -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif",
        "colorBgBase": "#383d3fb3"
      },
      "components": {
        "Layout": {
          "headerBg": "rgb(56, 61, 63)",
          "bodyBg": "rgb(48, 53, 55)"
        },
        "Input": {
          "activeBg": "rgb(56, 61, 63)"
        },
        "Modal": {
          "headerBg": "rgb(56, 61, 63)",
          "contentBg": "rgb(56, 61, 63)",
          "colorBgMask": "rgba(0, 0, 0, 0.65)"
        },
        "Menu": {
          "itemHeight": 50,
          "borderRadiusLG": 0,
          "itemHoverBg": "rgb(35, 73, 85)",
          "itemMarginBlock": 0,
          "itemMarginInline": 0
        },
        "Table": {
          "filterDropdownBg": "rgb(48, 53, 55)",
          "bodySortBg": "rgba(250, 250, 250, 0)",
          "headerBg": "rgba(48, 53, 55, 0.7)",
          "headerSortActiveBg": "rgba(48, 53, 55, 0.7)",
          "headerSortHoverBg": "rgba(48, 53, 55, 0.7)",
          "rowHoverBg": "rgba(38, 38, 38, 0.7)"
        },
        "Pagination": {
          "itemActiveBg": "rgb(48, 53, 55, 0.7)"
        },
        "Tooltip": {
          "colorBgSpotlight": "rgb(67, 67, 67)"
        },
        "Button": {
          "primaryShadow": "0 0px 0 rgba(5, 145, 255, 0.1)"
        }
      }
    }}
  >    <App />
  </ConfigProvider>,
);
