import React, { PureComponent } from 'react';
import { Mutation } from '@apollo/react-components';
import { Button, Form, InputNumber, message } from 'antd/es';
import { REORDER_QUESTIONS } from '../../graphql/tasks/questions/reorder-questions';
import './Forms.scss';

class ReorderTaskQuestionForm extends PureComponent {
  render() {
    // Set up form
    const layout = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
    };

    return (
      <Mutation
        mutation={REORDER_QUESTIONS}
        onError={(data) => {
          message.error(`Sorry, an error occurred`, 4);
        }}
        onCompleted={(data) => {
          message.success(`The question has been reordered successfully`, 4);

          /* Close modal */
          this.props.reorderTaskQuestionModalVisible();
        }}
      >
        {(reorderTaskQuestion, { loading, error, data }) => {
          return (
            <Form
              {...layout}
              name="ReorderTaskQuestionForm"
              className="reorder-task-question-form"
              layout="vertical"
              onFinish={(values) => {
                reorderTaskQuestion({
                  variables: {
                    input: {
                      taskId: this.props.taskId,
                      sectionId: this.props.sectionId,
                      questionId: this.props.questionId,
                      ordering: values.ordering,
                    },
                  },
                });
              }}
            >
              <Form.Item
                label={`Question position (total questions: ${this.props.questionCount})`}
                name="ordering"
                validateTrigger={['onBlur', 'onChange']}
                rules={[
                  {
                    required: true,
                    message: `Question position must be between 1 and ${this.props.questionCount}`,
                    type: 'number',
                    min: 1,
                    max: this.props.questionCount,
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                name="submitButton"
                className="u-text-center"
                wrapperCol={{ ...layout.wrapperCol, offset: 0 }}
              >
                <Button type="primary" htmlType="submit" loading={loading}>
                  {loading ? 'Reordering question' : 'Reorder question'}
                </Button>
              </Form.Item>
            </Form>
          );
        }}
      </Mutation>
    );
  }
}

export default ReorderTaskQuestionForm;
